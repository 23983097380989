import { alertConstants } from 'modules' // _constants
import { _ } from 'helpers'
import { displayHumanFutureDate } from 'utils'

export const alertActions = {
    success,
    error,
    warning,
    info,
    clear,
    auth,
    expiration,
}

function success(message, options = []) {
    return {
        type: alertConstants.SUCCESS,
        message,
        options,
    }
}

function error(message, options = []) {
    return { type: alertConstants.ERROR, message, options }
}

function warning(message, options = []) {
    return { type: alertConstants.WARNING, message, options }
}

function info(message, options = []) {
    return { type: alertConstants.INFO, message, options }
}

function clear(index) {
    return { type: alertConstants.CLEAR, index }
}

function auth() {
    const options = [{
        url: '/login',
        label: _('auth.loginButton'),
        color: 'secondary',
        size: 'small',
    }]
    return { type: alertConstants.INFO, message: _('app.authError'), options }
}

// notify account expiration
function expiration({ user }) {
    const date = user.end ? displayHumanFutureDate(user.end) : false
    if (!date) return { type: null }
    return { type: alertConstants.INFO, message: _('app.expiration', { date }), options: [] }
}