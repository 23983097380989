import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable' // uses browserlist in package.json
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import { store, history } from 'helpers'

import App from 'app'

render(
    <Provider store={store}>
        <Router history={history}>
            <Route path="/" component={App} />
        </Router>
    </Provider>,
    document.getElementById('root')
)
