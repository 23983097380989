import isEmpty  from 'lodash/isEmpty'

export function authHeader() {
    // return authorization header with jwt token    
    const auth = JSON.parse(localStorage.getItem('auth'))

    if (auth && auth.token) {
        return { Authorization: 'Bearer ' + auth.token }
    } else {
        return {}
    }
}

export function authenticated() {
    return !isEmpty(authHeader())
}