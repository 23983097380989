import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#a5c1aa',
            contrastText: '#fff',
        },
        secondary: {
            main: '#f29269',
            contrastText: '#fff',
        },
        dark: {
            main: '#2D3038',
        },
    },
})
