import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    button: {
        
    },
    title: {
        display: 'none',
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    drawer: {
        width: 350,
        maxWidth: '100vw',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        justifyContent: 'flex-end',
    },
    avatar: {
        margin: theme.spacing(3, 0, 2, 2),
        background: theme.palette.primary.main,
    },
    menutitle: {
        margin: theme.spacing(2, 0, 2, 2),
        fontSize: 18,
    },
}))
