import React from 'react'
import BaseButton from '@material-ui/core/Button'
import { useStyles } from './Button.styles'
import { _, history } from 'helpers'


export const ButtonList = ({ children }) => {
    const classes = useStyles()
    return <div className={classes.buttonlist}>{children}</div>
}

export const Button = ({
    label,
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    to,
    onClick = () => history.push(to),
    ...props
}) => {
    const classes = useStyles()
    return (
        <BaseButton
            className={classes.button}
            variant={variant}
            color={color}
            size={size}
            onClick={onClick}
            {...props}
        >{label}</BaseButton>
    )
}

export const BackButton = () => {
    return (
        <Button
            color="secondary"
            onClick={history.goBack}
            label={_('common.back')}
        />
    )
}

export default Button
