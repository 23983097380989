import React from 'react'
import { connect } from 'react-redux'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import Grid from '@material-ui/core/Grid'

import Card from 'components/Card'
import Pageheader from 'components/Pageheader'
import { ButtonList, BackButton } from 'components/Button'

import { _ } from 'helpers'
import { displayDate } from 'utils'
import { eventActions } from 'modules'

const Events = ({ events }) => {
    if (!events || events.length === 0) return null

    return map(events, (event, key) => {
        const url = '/event/' + event.slug
        const title = event.name
        // const subtitle = event.name
        const date = displayDate(event.end)
        return (
            <Grid key={key} xs={12} md={6} item>
                <Card
                    key={key}
                    url={url}
                    title={title}
                    date={date}
                />
            </Grid> 
        )
    })
}

class EventList extends React.Component {
    
    state = {
        type: undefined,
        selectedCategory: undefined,
        value: '',
    }

    render() {
        const { events } = this.props

        return (
            <>
                <Pageheader title={_('app.events')} />
                <Grid container spacing={2} alignItems="stretch">
                    <Events events={events} />
                </Grid>
                <ButtonList>
                    <BackButton />
                </ButtonList>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { events } = state

    return {
        events: sortBy(events, ['end'])
    }
}

const mapDispatchToProps = {
    listEvent: eventActions.list,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList)
