import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
// import { init, Integrations } from '@sentry/browser'

import config from 'config'
import {
    auth,
    alert,
    category,
    event,
    option,
    post,
    authConstants } from 'modules'

      
export const appReducer = combineReducers({
    auth,
    alerts: alert,
    categories: category,
    events: event,
    options: option,
    posts: post,
})

const rootReducer = (state, action) => {
    if (action.type === authConstants.LOGOUT) {
        state = undefined
    }

    return appReducer(state, action)
}

const loggerMiddleware = createLogger({ collapsed: true, diff: true })

const middlewares = [
    thunkMiddleware,
    // ...(env.sentryDSN ? [sentryMiddleware] : []),
    ...(config.debugMode ? [loggerMiddleware] : []),
]

// Sentry Initialization
// if (config.sentryDSN) {
//     init({
//         dsn: env.sentryDSN,
//         environment: process.env.REACT_APP_ENV,
//         integrations: [new Integrations.Breadcrumbs({ console: false })],
//     })
// }

export const store = createStore(rootReducer, applyMiddleware(...middlewares))
