import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    bottombar: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        zIndex: 10,
    },
    action: {
        color: 'white',
    }
}))