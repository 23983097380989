import React from 'react'
import { connect } from 'react-redux'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'
import Grid from '@material-ui/core/Grid'

import Pageheader from 'components/Pageheader'
import { CategoryCard } from 'components/Card'

import { alertActions } from 'modules'
import { _ } from 'helpers'

const Categories = ({ categories, alert }) => {
    if (!categories || categories.length === 0) return null

    return map(categories, (category, key) => {
        const url = '/search/category/' + category.slug
        const subheader = _('app.posts', { count: category.post_count })
        const title = category.name
        const icon = category.icon
        return (
            <Grid key={key} xs={12} md={6} item>
                <CategoryCard
                    key={key}
                    url={url}
                    subheader={subheader}
                    title={title}
                    icon={icon}
                    alert={alert}
                />
            </Grid> 
        )
    })
}

class CategoryList extends React.Component {

    render() {
        const { categories } = this.props
        const count = values(categories)
            .map(category => category.post_count)
            .reduce((a, b) => a + b, 0)

        const subtitle = _('app.posts', { count })
            
        return (
            <div className="categorypage">
                <Pageheader title={_('app.categories')} subtitle={subtitle} />
                <Grid container spacing={2} justify="center" alignItems="stretch">
                    <Categories categories={categories} alert={this.props.authAlert} />
                </Grid>   
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { categories } = state
    return {
        categories: sortBy(categories, ['lft'])
    }
}

const mapDispatchToProps = { 
    authAlert: alertActions.auth,
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)
