import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
// import WorkIcon from '@material-ui/icons/Work'
// import EventIcon from '@material-ui/icons/Event'
import LaunchIcon from '@material-ui/icons/Launch'

import { _, authenticated, history } from 'helpers'
import { getOptionByName } from 'modules'

import { useStyles } from './Bottombar.styles'


const Bottombar = ({ alert, options }) => {

    const classes = useStyles()


    const url = getOptionByName(options, 'url_emploiquebec')

    const protectedClick = url => {
        if (!authenticated()) {
            alert()
        } else {
            history.push(url)
        }
    }

    return (
        <BottomNavigation
            showLabels
            className={classes.bottombar}
        >
            <BottomNavigationAction
                label="Emplois02"
                icon={<LaunchIcon />}
                classes={{ root: classes.action }}
                target="_blank"
                href="https://emplois02.ca/"
            />
            <BottomNavigationAction
                label="Indeed"
                icon={<LaunchIcon />}
                classes={{ root: classes.action }}
                target="_blank"
                href="https://ca.indeed.com/"
            />
            <BottomNavigationAction
                label={_('app.launcheq')}
                icon={<LaunchIcon />}
                classes={{ root: classes.action }}
                target="_blank"
                href={url}
            />
        </BottomNavigation>
    )
}

export default Bottombar
